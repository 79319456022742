import { FC, useState } from "react";
import { Link } from "react-router-dom";
import Price from "../Price";

export type CardProps = {
	imageSrc: string;
	imageAlt: string;
	title: string;
	price: number;
	discountPrice?: number;
	description?: string;
	id: string;
};

export const Card: FC<CardProps> = (props) => {
	const { imageAlt, imageSrc, title, price, discountPrice, id, description } = props;
	const [src, setSrc] = useState(imageSrc);

	return (
		<div data-role="card" className="bg-white border-sand-500 border border-solid rounded-lg overflow-hidden cursor-pointer active:shadow">
			<Link to={`/prodotti/${title}?id=${id}`}>
				<div data-role="card-cover" className="h-48 relative overflow-hidden">
					<img
						src={src}
						alt={imageAlt}
						className="object-contain m-auto h-full w-full"
						onError={() => {
							setSrc("/assets/imageNotFound.jpg");
						}}
						loading="lazy"
					/>
				</div>
			</Link>
			<div data-role="card-body" className="py-2 px-4 border-t border-x-0 border-b-0 border-solid border-sand-500">
				<Link to={`/prodotti/${title}?id=${id}`} className="hover:underline">
					<p className="text-lg font-semibold text-bulrush-400 text-ellipsis break-keep pr-2 mb-2 whitespace-nowrap overflow-hidden" title={title}>
						{title}
					</p>
				</Link>
				{price ? (
					<div className="flex justify-between items-center">
						<Price price={price} discountPrice={discountPrice} size="small" />
					</div>
				) : (
					<span>{description}</span>
				)}
			</div>
		</div>
	);
};
