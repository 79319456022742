import { useQueries } from "@tanstack/react-query";
import { Button, Skeleton } from "antd";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StrapiClient } from "../api/instance";
import { BrandsProps, PaginationProps, ProductProps, Services } from "../api/type";
import Carousel from "../components/Carousel";
import Price from "../components/Price";
import { axiosExtract, usePageBrake, useScrollToSmooth } from "../utils";
import { noRetchDefault } from "../utils/react-query";
import landing1 from "../assets/images/product2.jpg";
import landing2 from "../assets/images/product3.jpg";
import landing3 from "../assets/images/product4.jpg";
import { Trans, useTranslation } from "react-i18next";
import MetaTag from "@/components/MetaTag";

export type LandscapeImageProps = {
	src: string;
	alt: string;
};

export const LandscapeImage = (props: LandscapeImageProps) => {
	return (
		<div className="h-[80vh] xl:h-[70vh] overflow-hidden relative">
			<img {...props} className="object-cover w-full h-full" loading="eager" />
		</div>
	);
};

type ProductsPromiseProps = {
	meta: { pagination: PaginationProps };
	data: Array<{ id: number; attributes: ProductProps }>;
};

type BrandsPromiseProps = {
	meta: { pagination: PaginationProps };
	data: Array<{ id: number; attributes: BrandsProps }>;
};

type ServicesPromiseProps = {
	meta: { pagination: PaginationProps };
	data: Array<{ id: number; attributes: Services }>;
};

const Home = () => {
	const [selectedBrands, setSelectedBrand] = useState(0);

	useScrollToSmooth(0);
	const navigate = useNavigate();
	const { lg, xl } = usePageBrake(false);
	const { t } = useTranslation();

	const [products, brands, services] = useQueries({
		queries: [
			{
				queryKey: ["queryProducts"],
				queryFn: () => axiosExtract(StrapiClient.get<ProductsPromiseProps>("/products?sort=id:desc&populate=img")),
				...noRetchDefault,
			},
			{ queryKey: ["queryBrands"], queryFn: () => axiosExtract(StrapiClient.get<BrandsPromiseProps>("/brands?populate=img")), ...noRetchDefault },
			{
				queryKey: ["queryServices"],
				queryFn: () => axiosExtract(StrapiClient.get<ServicesPromiseProps>("/services?populate=img&sort=name")),
				...noRetchDefault,
			},
		],
	});

	const { data: discountedProducts } = products.data ?? {};
	const { data: landingBrands } = brands.data ?? {};
	const { data: serviceList } = services.data ?? {};
	const images: Array<LandscapeImageProps> = [
		{
			src: landing1,
			alt: "landing 1",
		},
		{
			src: landing2,
			alt: "landing 2",
		},
		{
			src: landing3,
			alt: "landing 3",
		},
	];

	const bidimensionalBrands = useMemo(() => {
		return landingBrands
			?.filter((brand) => brand.attributes.landing)
			.reduce((acc: Array<{ id: number; attributes: BrandsProps }[]>, curr: { id: number; attributes: BrandsProps }, idx: number) => {
				if (idx % 2 === 0) {
					acc.push([curr]);
				} else {
					acc[acc.length - 1].push(curr);
				}
				return acc;
			}, [])
			.filter((couple) => couple.length === 2);
	}, [landingBrands]);

	const currentBrands = useMemo(() => {
		const firstBox = {
			name: bidimensionalBrands?.[selectedBrands][0].attributes.name,
			img: bidimensionalBrands?.[selectedBrands][0].attributes.img.data.attributes,
		};

		const secondBox = {
			name: bidimensionalBrands?.[selectedBrands][1].attributes.name,
			img: bidimensionalBrands?.[selectedBrands][1].attributes.img.data.attributes,
		};

		return { firstBox, secondBox };
	}, [bidimensionalBrands, selectedBrands]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setSelectedBrand((prev) => {
				if (!bidimensionalBrands) {
					return 0;
				}

				if (prev === bidimensionalBrands.length - 1) {
					return 0;
				}
				return prev + 1;
			});
		}, 10000);

		return () => {
			clearInterval(intervalId);
		};
	}, [bidimensionalBrands]);

	if (products.isFetching || services.isFetching || brands.isFetching) {
		return (
			<>
				<section className="mb-16">
					<Skeleton.Button active={true} className="!w-full !h-[80dvh] xl:!h-[70dvh]" />
				</section>

				<section className="mx-4 mb-16">
					<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
						<Skeleton.Button active={true} className="!w-full !h-64" shape="square" />
						<Skeleton.Button active={true} className="!w-full !h-64" shape="square" />
						<Skeleton.Button active={true} className="!w-full !h-64" shape="square" />
						<Skeleton.Button active={true} className="!w-full !h-64" shape="square" />
					</div>
				</section>

				<section className="mx-4 mb-16">
					<div className="grid md:grid-cols-2 gap-4 h-[40dvh]">
						<div className="flex justify-center items-center">
							<Skeleton.Button active={true} className="!w-52 !h-52" shape="square" />
						</div>
						<div className="grid gap-8">
							<Skeleton active={true} className="!w-full" />
							<Skeleton active={true} className="!w-full" />
						</div>
					</div>
				</section>
			</>
		);
	}

	return (
		<>
			<MetaTag description={t("home.description.seo")} indexing={{ follow: true }} />
			<section className="mb-16">
				<div className="bg-white rounded-lg block">
					<Carousel type="single" scrollBehaviour="drag">
						{images.map((imageProps) => (
							<LandscapeImage {...imageProps} key={imageProps.alt} />
						))}
					</Carousel>
				</div>
			</section>

			<section className="mx-4 mb-16">
				<h2 className="font-semibold uppercase mb-4">Prodotti in sconto</h2>
				<Carousel type="multiple" slideToShow={discountedProducts?.length} scrollBehaviour="arrow">
					{discountedProducts?.map((card, i) => (
						<article className="px-4" key={i}>
							<div className="md:h-72 h-64 bg-white flex justify-center items-center overflow-hidden relative border-bulrush-400/10 border border-solid rounded-md rounded-b-none">
								<Link to={`/prodotti/${card.attributes.name}?id=${card.id}`} className="w-full h-full flex justify-center items-center">
									<div className="h-5/6 w-5/6 relative" title={card.attributes.name}>
										<img
											src={card.attributes.img.data.attributes.url}
											alt={card.attributes.img.data.attributes.name}
											className="object-contain"
											sizes="(max-width: 768px) 100vw"
											loading="lazy"
										/>
									</div>
								</Link>
							</div>
							<div className="p-4 bg-white rounded-b-md border-bulrush-400/10 border border-solid ">
								<Link to={`/prodotti/${card.attributes.name}?id=${card.id}`}>
									<p className="font-semibold text-lg hover:underline truncate" title={card.attributes.name}>
										{card.attributes.name}
									</p>
								</Link>
								{card.attributes.price ? (
									<Price price={card.attributes.price} size="small" discountPrice={card.attributes.discount_price ?? undefined} />
								) : (
									<p>{card.attributes.description}</p>
								)}
							</div>
						</article>
					))}
				</Carousel>
			</section>

			<section className="mx-4 mb-16">
				<h2 className="font-semibold uppercase text-right mb-4">Marchi</h2>
				<div className="grid md:grid-cols-2 gap-4 py-8">
					<div className="flex justify-center items-center order-2 md:order-1">
						<div className="relative">
							{lg ? (
								<>
									<div className="w-80 h-[22rem] bg-white md:mr-36 mr-32 relative border-bulrush-400/10 border border-solid rounded">
										<motion.div
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											transition={{ duration: 1 }}
											className="overflow-hidden h-full"
											key={currentBrands.firstBox.name + "div"}
										>
											<div className="h-full relative">
												<img
													src={currentBrands.firstBox.img?.url ?? ""}
													alt={currentBrands.firstBox.img?.name ?? ""}
													className="object-cover rounded h-full"
													sizes="(max-width: 768px) 100vw"
													loading="lazy"
												/>
											</div>
										</motion.div>
										<motion.div
											className="hidden lg:block rounded py-2 px-4 bg-white w-fit border-zinc-500 font-semibold absolute bottom-4 border-bulrush-400/10 border border-solid"
											initial={{
												opacity: 0,
												x: xl ? -100 : -70,
												visibility: "hidden",
											}}
											animate={{
												opacity: 1,
												x: xl ? -75 : -45,
												visibility: "visible",
											}}
											transition={{
												duration: 1,
											}}
										>
											{currentBrands.firstBox.name}
										</motion.div>
									</div>
									<div className="w-80 h-[22rem] -mt-6 md:ml-36 ml-32 relative border-bulrush-400/10 border border-solid rounded">
										<motion.div
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											transition={{ duration: 1 }}
											className="overflow-hidden h-full"
											key={currentBrands.secondBox.name + "div"}
										>
											<div className="h-full relative">
												<img
													src={currentBrands.secondBox.img?.url ?? ""}
													alt={currentBrands.secondBox.img?.name ?? ""}
													className="object-cover rounded h-full"
													sizes="(max-width: 768px) 100vw"
													loading="lazy"
												/>
											</div>
										</motion.div>
										<motion.div
											className="hidden lg:block rounded py-2 px-4 bg-white w-fit  border-zinc-500 font-semibold absolute bottom-4 md:inset-x-0 md:my-0 md:mx-auto border-bulrush-400/10 border border-solid"
											initial={{
												opacity: 0,
												x: xl ? 200 : 170,
												visibility: "hidden",
											}}
											animate={{
												opacity: 1,
												x: xl ? 165 : 135,
												visibility: "visible",
											}}
											transition={{
												duration: 1,
											}}
											key={currentBrands.secondBox.name}
										>
											{currentBrands.secondBox.name}
										</motion.div>
									</div>
								</>
							) : (
								<div className="w-[20rem] h-[20rem] bg-white relative border-bulrush-400/10 border border-solid rounded">
									<motion.div
										initial={{
											opacity: 0,
										}}
										animate={{
											opacity: 1,
										}}
										transition={{
											duration: 0.5,
										}}
										className="overflow-hidden h-full"
										key={currentBrands.secondBox.name + "div"}
									>
										<div className="h-full relative">
											<img
												src={currentBrands.secondBox.img?.url ?? ""}
												alt={currentBrands.secondBox.img?.name ?? ""}
												className="object-cover rounded h-full"
												sizes="(max-width: 768px) 100vw"
												loading="lazy"
											/>
										</div>
									</motion.div>
									<motion.div
										className="rounded py-2 px-4 bg-white w-fit border-zinc-500 font-semibold absolute bottom-4 shadow inset-x-0 my-0 mx-auto border-bulrush-400/10 border border-solid"
										initial={{
											opacity: 0,
											y: 40,
											visibility: "hidden",
										}}
										animate={{
											opacity: 1,
											y: 30,
											visibility: "visible",
										}}
										transition={{
											duration: 1,
										}}
										key={currentBrands.secondBox.name}
									>
										{currentBrands.secondBox.name}
									</motion.div>
								</div>
							)}
						</div>
					</div>
					<div className="flex flex-col md:order-2 order-1">
						<div className="my-auto md:m-auto">
							{lg ? (
								<>
									{" "}
									<p className="md:text-2xl text-xl">Ogni giorno selezioniamo</p>
									<p className="md:text-2xl text-xl">i marchi migliori</p>
									<p className="md:text-2xl text-xl">per voi.</p>
								</>
							) : (
								<p className="md:text-2xl text-xl text-center">Ogni giorno selezioniamo i marchi migliori per voi.</p>
							)}
						</div>
					</div>
				</div>
				<div className="flex justify-end">
					<Button type="default" size="large" onClick={() => navigate("/marchi")}>
						Scopri di più
					</Button>
				</div>
			</section>

			<section className="mx-4 mb-16">
				<h2 className="font-semibold uppercase mb-4">Su di noi</h2>
				<div className="grid lg:grid-cols-2 gap-4">
					<div className="flex flex-col justify-end xl:w-3/4 mx-auto">
						<p className={"md:text-lg text-center lg:mb-24"}>
							<Trans i18nKey="home.description" components={{ strong: <strong /> }} />
						</p>
						<div className="hidden lg:flex rounded-lg bg-white p-4 items-center ">
							<div>
								<p className="font-semibold text-lg">Puoi venire a trovarci</p>
								<p>
									lunedì al sabato <strong>8:00-13:00</strong> <strong>15:00-19:30</strong>
								</p>
								<p>
									tutte le domeniche <strong>9:00-12:30</strong> <strong>15:00-19:00</strong>
								</p>

								<div className="flex gap-2 items-center  overflow-hidden">
									<p>Posizione:</p>
									<div className="flex gap-4 items-end">
										<Link to="https://goo.gl/maps/oJjToxY9zNp3FYDh7" target="_blank" className="truncate underline  overflow-hidden">
											Via Gabrio Piola, 11, 20833 Giussano, Monza Brianza
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="relative">
							<div className="w-full h-[60vh] overflow-hidden relative rounded">
								<img
									src={"https://farmaciacorti-strapi-s3-images.s3.eu-central-1.amazonaws.com/Landing_f698521647.jpg"}
									alt={"landing"}
									className="object-cover h-full w-full"
									loading="lazy"
								/>
							</div>
						</div>
					</div>
					<div className="lg:hidden bg-white p-4  overflow-hidden">
						<div>
							<p className="font-semibold text-lg">Puoi venire a trovarci</p>
							<p>
								lunedì al sabato <strong>8:00-13:00</strong> <strong>15:00-19:30</strong>
							</p>
							<p>
								tutte le domeniche <strong>9:00-12:30</strong> <strong>15:00-19:00</strong>
							</p>

							<div className="flex gap-2 items-center  overflow-hidden">
								<p>Posizione:</p>
								<div className="flex gap-4 items-end">
									<Link to="https://goo.gl/maps/oJjToxY9zNp3FYDh7" target="_blank" className="truncate  underline overflow-hidden">
										Via Gabrio Piola, 11 ,Giussano, MB
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="mx-4 mb-16">
				<h2 className="font-semibold uppercase text-right mb-4">Servizi</h2>
				<div className="flex flex-wrap gap-4">
					{serviceList?.map((x) => (
						<div className="relative flex-1 basis-96 h-[30vh] lg:h-[40vh] transition-all" key={x.id}>
							<div className="absolute z-20 inset-x-0 bottom-0 flex items-center justify-end bg-white rounded-br rounded-bl px-2 py-2 lg:py-4">
								<p className="text-lg uppercase font-semibold text-right">{x.attributes.name}</p>
							</div>
							<div className="w-full h-full relative">
								<img
									src={x.attributes.img.data.attributes.url}
									alt={x.attributes.img.data.attributes.name}
									className="object-cover rounded h-full w-full"
									sizes="(max-width: 768px) 100vw"
									loading="lazy"
								/>
							</div>
						</div>
					))}
				</div>
			</section>
		</>
	);
};

export default Home;
