import { NavigationContext } from "@/components/Navigation/context";
import { useIntersection } from "@/utils/hooks";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Checkbox, Input, Modal, Skeleton, Tag } from "antd";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "react-use";
import { StrapiClient } from "../../api/instance";
import { BrandsProps, PaginationProps } from "../../api/type";
import { CircularProgress } from "../../components/Circularprogress";
import { axiosExtract, useScrollToSmooth } from "../../utils";
import MetaTag from "@/components/MetaTag";
import { Trans, useTranslation } from "react-i18next";
import { unpromisify } from "@/utils/promises";
import { motion } from "framer-motion";

type Brand = {
	name: string;
	image: string;
	category: string;
};

const brands = [
	{
		name: "Lumina Beauty",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura della pelle",
	},
	{
		name: "Blossom Cosmetics",
		image: "[Immagine Unsplash]",
		category: "Trucco e cosmetici",
	},
	{
		name: "Pure Glow",
		image: "[Immagine Unsplash]",
		category: "Prodotti per il viso e il corpo",
	},
	{
		name: "Serene Skincare",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura della pelle",
	},
	{
		name: "Petal Perfumes",
		image: "[Immagine Unsplash]",
		category: "Profumi e fragranze",
	},
	{
		name: "Velvet Lips",
		image: "[Immagine Unsplash]",
		category: "Prodotti per le labbra",
	},
	{
		name: "Enchanting Eyes",
		image: "[Immagine Unsplash]",
		category: "Prodotti per gli occhi",
	},
	{
		name: "Silk Haircare",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura dei capelli",
	},
	{
		name: "Radiant Nails",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura delle unghie",
	},
	{
		name: "Aura Wellness",
		image: "[Immagine Unsplash]",
		category: "Prodotti per il benessere e la cura del corpo",
	},
] satisfies Array<Brand>;

type BrandsPromiseProps = {
	meta: { pagination: PaginationProps };
	data: Array<{ id: number; attributes: BrandsProps }>;
};

function groupBrandsBy(brands: Array<Brand>, by: keyof Brand) {
	return brands.reduce<{ [key: string]: Array<Brand> }>((accumulator, brand) => {
		const groupedKey = brand[by];
		if (!accumulator[groupedKey]) {
			accumulator[groupedKey] = [];
		}
		accumulator[groupedKey].push(brand);
		return accumulator;
	}, {});
}

const brandCategories = Object.keys(groupBrandsBy(brands, "category"));
const scrollOffset = 300;

const Marchi = () => {
	const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

	const handleFilterMenu = () => setIsFilterMenuOpen(!isFilterMenuOpen);
	const searchRef = useRef<HTMLDivElement>(null);
	const fetchingRef = useRef(false);
	const { t } = useTranslation();

	useScrollToSmooth(0);
	const [isVisible] = useIntersection(searchRef, {
		root: null,
		rootMargin: "0px",
		threshold: 1,
	});

	const ctx = useContext(NavigationContext);
	const { search, onChangeNavigationLayout, onChangeText } = ctx ?? {};

	//infinite data loading
	async function getBrandsList(
		limit: number,
		offset: number = 1,
		search?: string,
	): Promise<{ rows: Array<{ id: number; attributes: BrandsProps }>; nextOffset: number | undefined }> {
		const { data: rows, meta } = await axiosExtract(
			StrapiClient.get<BrandsPromiseProps>(
				`/brands?sort=id&populate=img&populate=brand_categories&pagination[page]=${offset}&pagination[pageSize]=${limit}&pagination[withCount]=true${
					search ? `&filters[name][$containsi]=${search}` : ""
				}`,
			),
		);
		const nextOffset = meta.pagination.pageCount < offset + 1 ? undefined : offset + 1;
		fetchingRef.current = false;
		return { rows, nextOffset };
	}

	const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, remove } = useInfiniteQuery(
		["brands"],
		(ctx) => getBrandsList(20, ctx.pageParam as number, search),
		{
			getNextPageParam: (lastGroup) => lastGroup.nextOffset,
			onError: () => {
				fetchingRef.current = false;
			},
			keepPreviousData: true,
		},
	);

	useEffect(() => {
		if (searchRef.current && !isFetching) {
			if (!isVisible) {
				onChangeNavigationLayout?.({
					showSearch: true,
					searchVariant: "search",
					defaultSearchValue: search,
					async debouncedCallback() {
						remove();
						await fetchNextPage({ pageParam: 1 });
					},
				});
			} else {
				onChangeNavigationLayout?.({ showSearch: false, searchVariant: "search", defaultSearchValue: search });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(() => () => onChangeNavigationLayout?.({ showSearch: false, searchVariant: "search", defaultSearchValue: "" }), []);

	const [isReady] = useDebounce(
		async () => {
			remove();
			await fetchNextPage({ pageParam: 1 });
		},
		500,
		[search],
	);

	const rows = useMemo(() => (data ? data.pages.flatMap((d) => d.rows) : []), [data]);

	const handleScroll = useCallback(async () => {
		if (window.scrollY + window.innerHeight < document.body.scrollHeight - scrollOffset || !hasNextPage || fetchingRef.current) {
			return;
		}
		fetchingRef.current = true;
		await fetchNextPage();
	}, [fetchNextPage, hasNextPage]);

	useEffect(() => {
		window.addEventListener(
			"scroll",
			unpromisify(() => handleScroll()),
		);
		return () =>
			window.removeEventListener(
				"scroll",
				unpromisify(() => handleScroll()),
			);
	}, [handleScroll]);

	if (isFetching && !isFetchingNextPage) {
		return (
			<motion.section
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
				}}
				transition={{
					duration: 0.5,
				}}
				data-role="brand"
				className="m-4 xl:w-11/12 xl:mx-auto"
			>
				<Skeleton active={true} className="!w-full mb-8" />
				<Skeleton.Button active={true} className="!w-full !h-6 mb-8" shape="square" />
				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
					{Array.from({ length: 12 }).map((_, i) => (
						<Skeleton.Button active={true} className="!w-full !h-72" shape="square" key={i} />
					))}
				</div>
			</motion.section>
		);
	}

	return (
		<>
			<MetaTag description={t("brands.description.seo")} indexing={{ follow: true }} />
			<motion.section
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
				}}
				transition={{
					duration: 0.5,
				}}
				data-role="brand"
				className="m-4 xl:w-11/12 xl:mx-auto"
			>
				<div className="flex justify-between">
					<h2>Marchi</h2>
				</div>
				<p className="mb-4 whitespace-pre-line">
					<Trans
						i18nKey="brands.description"
						components={{
							strong: <strong />,
						}}
					/>
				</p>
				<div ref={searchRef} className="mb-4 lg:mb-12">
					<Input
						placeholder="Ricerca un prodotto..."
						value={search}
						onChange={(e) => onChangeText?.(e.currentTarget.value)}
						prefix={isReady() ? <MagnifyingGlassIcon width={24} height={24} /> : <CircularProgress />}
						size="large"
						className="transition-all"
					/>
				</div>
				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
					{rows
						.sort((a, b) => (a.attributes.name > b.attributes.name ? 1 : -1))
						.map((x) => (
							<div className="h-72 flex justify-center items-center rounded bg-white mb-2 relative overflow-hidden" key={x.attributes.name}>
								<div className="w-full h-full relative">
									<img
										src={x.attributes.img.data.attributes.url}
										alt={x.attributes.img.data.attributes.name}
										className="object-cover rounded-md hover:scale-110 transition-transform duration-500 w-full h-full"
										loading="lazy"
									/>
									<div className="absolute top-2 right-1">
										<div className="flex gap-1">
											{x.attributes.brand_categories.data.map((category, i) => (
												<Tag color="green" key={i}>
													{category.attributes.name}
												</Tag>
											))}
										</div>
									</div>
									<div className="absolute bottom-0 inset-x-0 w-full p-2 bg-gradient-to-t from-zinc-100 to-transparent">
										<h3 className="text-right text-xl">{x.attributes.name}</h3>
									</div>
								</div>
							</div>
						))}
				</div>
				{isFetching && (
					<div className="flex py-4 justify-center items-center">
						<div className="scale-110">
							<CircularProgress />
						</div>
					</div>
				)}
			</motion.section>
			<Modal
				title="Filtri"
				centered
				open={isFilterMenuOpen}
				onOk={handleFilterMenu}
				onCancel={handleFilterMenu}
				width={1000}
				styles={{ body: { overflowY: "auto", maxHeight: "calc(100vh - 200px)" } }}
			>
				<div className="w-full grid gap-4">
					<div className="rounded-md bg-white border">
						<div className="grid gap-2">
							{brandCategories.map((el) => (
								<Checkbox onChange={console.log} className="!ml-0" key={el}>
									{el}
								</Checkbox>
							))}
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Marchi;
